import React, { useCallback, useMemo, useState } from "react";
import cn from "classnames";

interface Props {
  value: string;
  className?: string;
  placeholder?: string;
  isMultiline?: boolean;
  onChange: (value: string) => any;
}

const TextInput = ({ isMultiline, className, onChange, ...props }: Props) => {
  const [isFocused, setIsFocused] = useState(false);

  const onValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChange(e.target.value);
    },
    [onChange]
  );

  const commonProps = useMemo((): React.HTMLAttributes<
    HTMLInputElement | HTMLTextAreaElement
  > => {
    return {
      ...props,
      onChange: onValueChange,
      className: "input__field",
      onFocus: () => setIsFocused(true),
      onBlur: () => setIsFocused(false),
    };
  }, [onValueChange, props]);

  return (
    <div
      className={cn("input", className, {
        "input--focused": isFocused,
        "input--multiline": isMultiline,
      })}
    >
      {isMultiline ? <textarea {...commonProps} /> : <input {...commonProps} />}
    </div>
  );
};

export default TextInput;
