import React from "react";
import ReactDOM from "react-dom";

import "./styles/index.scss";
import App from "./App";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://16c2298a1d514096948f871a838c66a5@o256655.ingest.sentry.io/6556070",
  integrations: [new BrowserTracing({ tracingOrigins: ["*"] })],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);
