import React, { useCallback, useState, useMemo } from "react";
import { Redirect, useParams, useHistory, Link } from "react-router-dom";
import cn from "classnames";

import { Review, submitFeedback } from "../services/api";
import { isAndroid } from "../utils/device";

import Loader from "../components/Loader";
import AppRoutes from "../constants/rotues";
import PoweredBy from "../components/PoweredBy";
import TextInput from "../components/TextInput";

type Params = { conversationId: string };

interface Props {
  setBelowExpectations: (value: boolean) => any;
}

const GetReview = ({ setBelowExpectations }: Props) => {
  const showGoogleReviewsLink = useMemo(() => isAndroid(), []);

  const [feedback, setFeedback] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { location } = useHistory<Review>();
  const { conversationId } = useParams<Params>();

  const review = location.state;
  const canSubmit = !isSubmitting && feedback.trim();

  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!feedback) return;

      setIsSubmitting(true);
      await submitFeedback(conversationId, feedback.trim());
      setIsSubmitted(true);
    },
    [conversationId, feedback]
  );

  const handleGoogleLinkClick = useCallback(() => {
    setBelowExpectations(true);
  }, []);

  if (isSubmitted) {
    return <Redirect to={AppRoutes.THANK_YOU} />;
  }

  if (!review) {
    return <Redirect to={AppRoutes.GET_SENTIMENT(conversationId)} />;
  }

  return (
    <form className="page page--hcenter" onSubmit={onSubmit}>
      <img className="page__logo" src={review.logo} alt="" />
      <p className="page__message">{review.negativeFeedbackText}</p>
      <TextInput
        isMultiline
        className="page__input"
        value={feedback}
        onChange={setFeedback}
        placeholder="Share your experience with us!"
      />
      <button
        className={cn("page__action", {
          "page__action--blue": canSubmit,
        })}
        disabled={!canSubmit}
      >
        {isSubmitting ? <Loader isSmall /> : "Share Experience"}
      </button>

      {review.googleReviewLink ? (
        <a
          className="button link text-size-small"
          href={review.googleReviewLink}
          style={{ margin: "24px 0" }}
          onClick={handleGoogleLinkClick}
        >
          Leave a review on Google instead
        </a>
      ) : null}

      <PoweredBy show={review.emitrrBranding} />
    </form>
  );
};

export default GetReview;
