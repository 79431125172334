import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import "./App.scss";
import NotFound from "./pages/NotFound";
import ThankYou from "./pages/ThankYou";
import ErrorPage from "./pages/ErrorPage";
import GetReview from "./pages/GetReview";
import AppRoutes from "./constants/rotues";
import GetSentiment from "./pages/GetSentiment";
import SelectReviewSite from "./pages/SelectReviewSite";

const App = () => {
  const [isBelowExpectations, setIsBelowExpectations] = useState(false);

  const setBelowExpectations = (value: boolean): void => {
    setIsBelowExpectations(value);
  };

  return (
    <Router>
      <Switch>
        <Route path={AppRoutes.NOT_FOUND}>
          <NotFound />
        </Route>
        <Route path={AppRoutes.ERROR_PAGE}>
          <ErrorPage />
        </Route>
        <Route path={AppRoutes.THANK_YOU}>
          <ThankYou />
        </Route>
        <Route path={AppRoutes.SELECT_REVIEW_SITE(":conversationId")}>
          <SelectReviewSite isBelowExpectations={isBelowExpectations} />
        </Route>
        <Route path={AppRoutes.GET_REVIEW(":conversationId")}>
          <GetReview setBelowExpectations={setBelowExpectations} />
        </Route>
        <Route exact path={AppRoutes.GET_SENTIMENT(":conversationId")}>
          <GetSentiment setBelowExpectations={setBelowExpectations} />
        </Route>
        <Redirect to={AppRoutes.NOT_FOUND} />
      </Switch>
    </Router>
  );
};

export default App;
