import React from "react";

const ThankYou = () => {
  return (
    <div className="page page--center">
      Thank you for your feedback.
    </div>
  );
};

export default ThankYou;
