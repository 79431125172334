const AppRoutes = {
  NOT_FOUND: "/not-found",
  ERROR_PAGE: "/error",
  THANK_YOU: "/thank-you",
  GET_SENTIMENT: (conversationId: string) => `/${conversationId}`,
  GET_REVIEW: (conversationId: string) => `/${conversationId}/review`,
  SELECT_REVIEW_SITE: (conversationId: string) =>
    `/${conversationId}/review/sites`,
};

export default AppRoutes;
