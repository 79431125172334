import React, { FC } from "react";
import { ReactComponent as EmitrrLogo } from "../assets/logo.svg";

interface Props {
  show: boolean;
}

const PoweredBy: FC<Props> = ({ show }) => {
  if (!show) {
    // auto margin work-around
    return <div aria-hidden className="page__branding-link" />;
  }

  return (
    <a
      className="page__branding-link"
      href="https://emitrr.com"
      // eslint-disable-next-line react/jsx-no-target-blank
      target="_blank"
    >
      <div className="page__powered-by">Powered by</div>
      <EmitrrLogo className="page__branding" />
    </a>
  );
};

export default PoweredBy;
