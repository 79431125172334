import React from "react";
import { ReactComponent as NotFoundIcon } from "../assets/404.svg";

const NotFound = () => {
  return (
    <div className="not-found">
      <NotFoundIcon className="not-found__icon" />
      <p className="not-found__message">This page does not exists</p>
    </div>
  );
};

export default NotFound;
