import React from "react";
import { useHistory } from "react-router-dom";

interface RouteState {
  message: string;
}

const ErrorPage = () => {
  const { location } = useHistory<RouteState>();
  return (
    <div className="page page--center">
      {location.state.message || "Some error occurred, please try after some time."}
    </div>
  );
};

export default ErrorPage;
