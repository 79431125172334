import React, { useMemo } from "react";
import { useHistory, useParams, Redirect } from "react-router-dom";

import { Review, ReviewUrls } from "../services/api";
import AppRoutes from "../constants/rotues";
import PoweredBy from "../components/PoweredBy";
import { isiOS } from "../utils/device";

type Params = { conversationId: string };

interface Props {
  isBelowExpectations: boolean;
}

const SelectReviewSite = ({ isBelowExpectations }: Props) => {
  const history = useHistory();
  const { conversationId } = useParams<Params>();
  const state = history.location.state as { review: Review; reviewUrls: ReviewUrls[] };
  const review = state?.review;
  const reviewUrls = state?.reviewUrls || [];
  const checkiOSDevice = useMemo(() => isiOS(), []);
  if (!review) {
    return <Redirect to={AppRoutes.GET_SENTIMENT(conversationId)} />;
  }
  let faceBookReviewLink;
  if (checkiOSDevice && review.facebookReviewLink && review.facebookReviewLink !== "" && (review.facebookReviewLink).includes("reviews")) {
    faceBookReviewLink = (review.facebookReviewLink).split("reviews")[0];
  } else {
    faceBookReviewLink = review.facebookReviewLink;
  }
  return (
    <div className="page page--hcenter">
      <img className="page__logo" src={review.logo} alt="" />
      <p className="page__message">
        {isBelowExpectations
          ? review.negativeFeedbackText
          : review.positiveFeedbackText}
      </p>
      {/* Check if reviewUrls array is empty */}
      {reviewUrls.length === 0 ? (
        <p className="page__message">No review sites available.</p>
      ) : (
        reviewUrls.map((url, index) => (
          <a
            key={index}
            className={`page__action page__action--${url.name.toLowerCase()}`}
            href={url.reviewUrl}
            target="_blank"
          >
            {url.name}
          </a>
        ))
      )}
      <PoweredBy show={review.emitrrBranding} />
    </div>
  );
};

export default SelectReviewSite;
